<template>
    <div id="login-mask"
         @keyup.enter="handleSubmit"
         v-if="!status.loggedIn">
        <v-form v-model="valid">

            <v-container class="login-input logo-container">
                <v-row justify="center">
                    <img alt="MK" src="../assets/MK_logo.svg" width="150" height="150">
                </v-row>
            </v-container>

            <v-container class="login-input">
                <v-row>
                    <v-col>
                        <v-text-field v-model="username"
                            prepend-inner-icon="mdi-account-box"
                            :label="$t('users.username')"
                            :rules="[rules.required]"
                            required></v-text-field>
                        <div v-show="submitted && !username">{{ $t('users.username_required') }}</div>
                    </v-col>
                </v-row>
            </v-container>

            <v-container class="login-input">
                <v-row>
                    <v-col>
                        <v-text-field v-model="password"
                            prepend-inner-icon="mdi-lock"
                            :append-icon="showPasswd ? 'mdi-eye-off' : 'mdi-eye'"
                            :rules="[rules.required, rules.min]"
                            :type="showPasswd ? 'text' : 'password'"
                            name="input-10-2"
                            :label="$t('settings.password.name')"
                            :hint="$t('password_char_min')"
                            value=""
                            class="input-group--focused"
                            @click:append="showPasswd = !showPasswd">
                        </v-text-field>
                        <div v-show="submitted && !password">{{ $t('settings.password.password_required') }}</div>
                    </v-col>
                </v-row>
            </v-container>

            <v-container class="login-input">
                <v-row>
                    <v-col md4>
                        <v-btn @click.prevent="handleSubmit" color="info" :disabled="(status.loggingIn || !username || !password)">{{ $t('login')}}</v-btn>
                        <span class="spinner" style="margin-left:.5em;vertical-align:text-top;"
                            v-if="status.loggingIn"></span>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>


<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            username: '',
            password: '',
            submitted: false,

            showPasswd: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => ('The email and password you entered don\'t match'),
            },

            valid: false,
        }
    },

    computed: {
        ...mapState('account', ['status', 'user']),
    },

    created() {
        // reset login status
        if (this.status.loggedIn) {
            this.clearAlert()
            this.logout()
        }
    },

    methods: {
        ...mapActions({
            clearAlert: 'alert/clear',
            showSpinner: 'page/showSpinner',
            login: 'account/login',
            logout: 'account/logout',
        }),
        handleSubmit(e) {
            this.submitted = true
            const { username, password } = this
            if (username && password) {
                this.login({ username, password, next: this.$route.query.next })
                    .then(result => {
                        if (result) {
                            this.clearAlert()
                            this.$i18n.locale = this.user.user.lang
                        }
                    })
            }
        },
        submit() {
            this.$v.$touch()
        },
    },

    mounted() {
        this.showSpinner(false)
    },
}
</script>


<style lang="stylus">
    #login-mask
        margin-top 2%

    .login-input
        max-width 500px

    .logo-container
        display flex
        justify-content space-around

    .login-input .spinner
        position relative

    .login-input .spinner:before
        top 100%
        left 1.5em
        width 1.5em
        height 1.5em
        border 2px solid #ccc
        border-top-color #ee7214
</style>
