export function getToken() {
    // return authorization header with jwt token
    const user = JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_KEY))
    let userToken = ''
    if (user && user.token) {
        userToken = user.token
    }

    return userToken
}
