const state = {
    type: null,
    message: null,
}


const actions = {
    success({ commit }, message) {
        commit('success', message)
    },
    warning({ commit }, message) {
        commit('warning', message)
    },
    info({ commit }, message) {
        commit('info', message)
    },
    error({ commit }, message) {
        commit('error', message)
    },
    clear({ commit }, message) {
        commit('success', message)
    },
}


const mutations = {
    success(alertState, message) {
        alertState.type = 'success'
        alertState.message = message
    },
    warning(alertState, message) {
        alertState.type = 'warning'
        alertState.message = message
    },
    info(alertState, message) {
        alertState.type = 'info'
        alertState.message = message
    },
    error(alertState, message) {
        alertState.type = 'error'
        alertState.message = message
    },
    clear(alertState) {
        alertState.type = null
        alertState.message = null
    },
}


export const alert = {
    namespaced: true,
    state,
    actions,
    mutations,
}
