<template>
    <v-app style="overflow:unset;background-color:#fff">

        <div v-if="account.status.loggedIn">

          <Toolbar :title="title" />

          <PageNavigation :visible="page.drawer" />
        </div>

        <v-main id="main-content">
            <v-alert style="width: 100%; position: absolute; z-index: 5;"
                    :value="alert.message ? true : false"
                    dismissible
                    :type="`${alert.type ? alert.type : 'success'}`">
                {{ alert.message }}
            </v-alert>

            <AppDialog :id="input.data.id"
                :dialog="input.data.visible"
                :type="input.data.type ? input.data.type : input.new.type"
                :title="input.data.title ? input.data.title : input.new.title"
                :inputData="input.data.data"
                :confirm="input.data.confirm"
                :preValidate="input.data.preValidate"
                :modified="input.data.type ? input.data.modified : input.new.modified"/>

            <div class="spinner"
                 v-if="page.spinner">
            </div>

            <router-view :key="$route.fullPath" />

            <v-dialog
                v-model="page.confirmDialog"
                width="500"
                >
                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        {{ page.confirmData && page.confirmData.title }}
                    </v-card-title>

                    <v-card-text class="pt-5">
                        {{ page.confirmData && page.confirmData.text}}
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn
                            color="error"
                            text
                            @click="setConfirmDialogData(null)"
                        >
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        text
                        @click="confirmOk"
                    >
                        {{ $t('ok') }}
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-snackbar
                v-model="page.snackbar"
                app
                top
                :timeout="page.snackbarType === 'success' ? 3000 : -1"
                :color="`${page.snackbarType ? page.snackbarType : 'success'}`"
                >
                <v-card
                    flat
                    color="rgba(0,0,0,0)">

                    <v-card-title>
                        {{ page.snackbarText }}
                    </v-card-title>

                    <v-card-subtitle>

                    </v-card-subtitle>
                </v-card>

                <template v-slot:action="{ attrs }">
                    <v-btn
                        fab
                        v-bind="attrs"
                        @click="showSnackbar(false)"
                        >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </template>
            </v-snackbar>

        </v-main>
    </v-app>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import { rest } from './_helpers'
import PageNavigation from './components/PageNavigation.vue'
import Toolbar from './components/Toolbar.vue'

const moment = require('moment-timezone')

export default {
    name: 'App',

    data: () => ({
        title: 'Dashboard',
    }),

    components: {
        PageNavigation,
        Toolbar,
        AppDialog: () => import(/* webpackChunkName: "dialog" */ './components/Dialog.vue'),
    },

    computed: {
        ...mapState({
            alert: state => state.alert,
            page: 'page',
            account: 'account',
            input: 'input',
        }),
    },

    methods: {
        ...mapActions({
            setSortItems: 'table/setSortItems',
            showSearch: 'page/showSearchButton',
            setSearch: 'table/setSearchText',
            setSorting: 'table/setSorting',
            setNew: 'input/setNew',
            setInputDialog: 'input/setInputDialog',
            showDialog: 'input/showDialog',
            setGauges: 'page/setGauges',
            clearAlerts: 'alert/clear',
            showSnackbar: 'page/showSnackbar',
            setCriticalCron: 'page/setCriticalCron',
            setTitle: 'page/setTitle',
            setConfirmDialogData: 'page/setConfirmDialogData',
        }),

        checkCronTask() {
            rest.getRows('heartbeat', { query: { systemId: 0 }, project: { date: true } })
                .then(res => {
                    if (res && res.length) {
                        const last = moment(res[0].date)
                        const isCritical = moment().diff(last, 'minute') > 15

                        this.setCriticalCron(isCritical)
                    }
                })
        },

        confirmOk() {
            if (this.page.confirmData) {
                this.page.confirmData.func()
            }
            this.setConfirmDialogData(null)
        },
    },

    created() {
        if (this.account.user.user) {
            this.$i18n.locale = this.account.user.user.lang
        }
    },

    watch: {
        $route(to, from) {
            // reset all necessary redux values
            this.setNew(null)
            this.setSorting(null)
            this.setSortItems([])
            this.showSearch(to.meta.search)
            this.setGauges([])
            this.setInputDialog(null)
            this.showDialog(false)
            this.clearAlerts()
            this.setSearch('')
            this.setTitle({})

            this.title = to.meta.title

            if (this.account.status.loggedIn) {
                this.checkCronTask()
            }
        },
    },
}
</script>


<style>
    /*
    /@import './assets/css/fonts.css';
    */
</style>


<style lang="stylus">
    *
        outline none !important

    html
        overflow hidden !important
        font-size 80%

    html, body
        width 100%
        height 100%
        position relative
        padding 0
        margin 0

    a
        text-decoration none

    #main-content .v-main__wrap
        overflow-y auto

    .v-responsive__content
        width unset

    .theme--light.v-sheet
        color rgba(0,0,0,.7)

    .text
        font-size 14px
        font-weight 500

    hr
        border 0
        border-bottom 1px solid rgba(120,120,120, .5)

    @keyframes spinner
        to
            transform rotate(360deg)

    .spinner:before
        content ''
        box-sizing border-box
        position absolute
        top 50%
        left 50%
        width 3em
        height 3em
        margin-top -1.5em
        margin-left -1.5em
        border-radius 50%
        border 1px solid #ccc
        border-top-color #ee7214
        animation spinner .6s linear infinite

    .topbar-logo
        vertical-align text-bottom
        width 50px
        margin-right .2em

    .v-content__wrap
        z-index 0

    body
        color rgba(0, 0, 0, 0.87)

    #app
        height 100%
        overflow hidden

    #main-content
        height 100%

    @media (max-width: 959px)
        html
            font-size 77%
</style>
