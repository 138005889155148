<template>
<div class="no-print">
    <v-navigation-drawer v-model="isVisible"
        clipped
        fixed
        app
        temporary>
        <v-list>
            <v-list-item class="pl-3">
                <v-list-item-action>
                    <v-btn icon
                            class="px-0 mx-0"
                           style="min-width:unset;"
                           @click="close('settings')">
                        <v-avatar color="orange red--after title white--text"
                                    size="30">
                            <span class="white--text headline"
                                style="position:absolute;">
                                {{ userLetter }}
                            </span>
                            <img
                                :src="require('../assets/icons/'+account.user.user.avatar+'.png')"
                            >
                        </v-avatar>
                    </v-btn>
                </v-list-item-action>

                <v-list-item-content>
                    <v-list-item-title>{{ account.user.user.displayname }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-list class="pt-0">
            <v-divider></v-divider>

            <template v-for="(item, idx) in items">
                <v-list-item v-if="!item.items"
                    :key="idx"
                    @click="close(item.name)">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group v-else
                    :key="'no_'+idx"
                    :value="true"
                    :prepend-icon="item.icon"
                    no-action>

                    <template v-slot:activator>
                        <v-list-item-content @click="close(item.name)">
                            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <div v-for="(child, cidx) in item.items"
                        :key="`child_${cidx}`"
                        class="ml-5">
                        <v-list-item v-if="!child.menu"
                            @click="close(item.name, child.value)">
                            <v-list-item-action>
                                <v-icon>{{ child.icon }}</v-icon>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t(child.name) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-menu v-else offset-x>
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-list-item-action>
                                        <v-icon>{{ child.icon }}</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="(menuItem, menuIndex) in child.menu"
                                    :key="menuIndex"
                                    @click="close(item.name, menuItem.name)">
                                    <v-list-item-action>
                                        <v-icon>{{ menuItem.icon }}</v-icon>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t(menuItem.name) }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>


                </v-list-group>
            </template>
        </v-list>
    </v-navigation-drawer>
</div>
</template>


<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: { visible: Boolean },

    data() {
        return {
            items: [],
            isVisible: this.visible,
            userLetter: '',
        }
    },

    computed: {
        ...mapState({
            account: 'account',
        }),
    },

    methods: {
        ...mapActions({
            showDrawer: 'page/showDrawer',
        }),

        close(parent, path) {
            const route = `/${parent}${path ? `/${path}` : ''}`

            if (parent && route !== this.$route.fullPath) {
                this.$router.push(route)
            }

            this.isVisible = false
        },
    },

    created() {
        const user = JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_KEY))

        this.$router.options.routes.forEach(route => {
            if (route.menu) {
                if (route.meta.role == null || user.user.role <= route.meta.role) {
                    this.items.push({
                        icon: route.icon,
                        title: route.meta.title,
                        name: route.name,
                        items: route.meta.items,
                    })
                }
            }
        })

        this.userLetter = this.account.user.user.displayname[0].toUpperCase()
    },

    watch: {
        visible() {
            this.isVisible = this.visible
        },
        isVisible() {
            this.showDrawer(this.isVisible)
        },
    },
}
</script>
