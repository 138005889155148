import { userService } from '../_services'


const state = {
    all: {},
}


const actions = {
    getAll({ commit }) {
        commit('getAllRequest')

        userService.getAll()
            .then(
                users => commit('getAllSuccess', users),
                error => commit('getAllFailure', error),
            )
    },

    delete({ commit }, id) {
        commit('deleteRequest', id)

        userService.delete(id)
            .then(
                user => commit('deleteSuccess', id),
                error => commit('deleteSuccess', { id, error: error.toString() }),
            )
    },
}


const mutations = {
    getAllRequest(usersState) {
        usersState.all = { loading: true }
    },
    getAllSuccess(usersState, users) {
        usersState.all = { items: users }
    },
    getAllFailure(usersState, error) {
        usersState.all = { error }
    },
    deleteRequest(usersState, id) {
        // add 'deleting:true' property to user being deleted
        usersState.all.items = usersState.all.items.map(user => (
            user.id === id
                ? { ...user, deleting: true }
                : user
        ))
    },
    deleteSuccess(usersState, id) {
        // remove deleted user from state
        usersState.all.items = usersState.all.items.filter(user => user.id !== id)
    },
    deleteFailure(usersState, { id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user
        usersState.all.items = usersState.items.map(user => {
            if (user.id === id) {
                // make copy of user without 'deleting:true' property
                const { deleting, ...userCopy } = user
                // return copy of user with 'deleteError:[error]' property
                return { ...userCopy, deleteError: error }
            }

            return user
        })
    },
}


export const users = {
    namespaced: true,
    state,
    actions,
    mutations,
}
