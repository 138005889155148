/* eslint-disable global-require */
import Vue from 'vue'
import VueQriously from 'vue-qriously'
import { Icon } from 'leaflet'
import App from './App.vue'
import router from './router'
import { store } from './_store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'leaflet/dist/leaflet.css'
import i18n from './i18n'

// this part resolve an issue where the markers would not appear
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.config.productionTip = false

Vue.use(VueQriously)

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app')
