const state = {
    title: {
        name: '',
        sub: null,
        id: null,
    },
    spinner: false,
    drawer: false,
    notificationCount: 0,
    userName: '',
    userLetter: '',

    pagination: {},
    sorting: [],
    sortItems: [],
    sortOrder: 'asc',

    search: false,
    searchButton: true,
    searchText: null,

    gauges: [],

    snackbar: false,
    snackbarText: '',
    snackbarTimeout: -1,

    criticalCron: false,

    confirmDialog: false,
    confirmData: null,
}


const actions = {
    setTitle({ commit }, titleObject) {
        commit('setTitle', titleObject)
    },
    showSpinner({ commit }, show) {
        commit('showSpinner', show)
    },
    showDrawer({ commit }, show) {
        commit('showDrawer', show)
    },
    incrNotifier({ pageState, commit }) {
        commit('setNotificationCount', pageState.notificationCount + 1)
    },
    setName({ commit }, name) {
        commit('setName', name)
    },
    toggleSearchBar({ commit }) {
        commit('toggleSearchBar')
    },
    showSearchButton({ commit }, show) {
        commit('showSearchButton', show)
    },
    setSearchText({ commit }, search) {
        commit('setSearchText', search)
    },
    setSorting({ commit }, sorting) {
        commit('setSorting', sorting)
    },
    setSortOptions({ commit }, options) {
        commit('setSortOptions', options)
    },
    setSortItems({ commit }, items) {
        commit('setSortItems', items)
    },
    showInputDialog({ commit }, show) {
        commit('showInputDialog', show)
    },
    setInputDialog({ commit }, inputData) {
        commit('setInputDialog', inputData)
    },
    setNew({ commit }, newInput) {
        commit('setNew', newInput)
    },
    addGauge({ commit }, gauge) {
        commit('addGauge', gauge)
    },
    setGauges({ commit }, gauges) {
        commit('setGauges', gauges)
    },

    setSnackbar({ commit }, snackData) {
        commit('setSnackbar', snackData)
    },
    showSnackbar({ commit }, show) {
        commit('showSnackbar', show)
    },
    setCriticalCron({ commit }, critical) {
        commit('setCriticalCron', critical)
    },
    showConfirmDialog({ commit }, show) {
        commit('showConfirmDialog', show)
    },
    setConfirmDialogData({ commit }, data) {
        commit('setConfirmDialogData', data)
    },
}


const mutations = {
    setTitle(pageState, title) {
        pageState.title = title
    },
    showSpinner(pageState, show) {
        pageState.spinner = show
    },
    showDrawer(pageState, show) {
        pageState.drawer = show
    },
    setNotificationCount(pageState, count) {
        pageState.notificationCount = count
    },
    setName(pageState, name) {
        pageState.userLetter = name.charAt(0)
        pageState.username = name
    },
    toggleSearchBar(pageState) {
        pageState.search = !state.search
    },
    showSearchButton(pageState, show) {
        pageState.searchButton = show
        pageState.search = false
    },
    setSearchText(pageState, search) {
        pageState.searchText = search
    },
    setSorting(pageState, sorting) {
        pageState.sorting = sorting
        pageState.pagination.sortBy = sorting
        pageState.pagination.itemsPerPage = -1
    },
    setSortOptions(pageState, options) {
        pageState.pagination = options
    },
    setSortItems(pageState, items) {
        pageState.sortItems = items
    },
    showInputDialog(pageState, show) {
        pageState.inputDialog.visible = show
    },
    setInputDialog(pageState, inputData) {
        Object.assign(pageState.inputDialog, inputData)
        // state.inputDialog = inputData
    },
    setNew(pageState, newInput) {
        Object.assign(pageState.newInput, newInput)
        // .newInput = newInput
    },
    addGauge(pageState, gauge) {
        pageState.gauges.push(gauge)
    },
    setGauges(pageState, gauges) {
        pageState.gauges = gauges
    },

    setSnackbar(pageState, snackData) {
        pageState.snackbarText = snackData.text
        pageState.snackbarType = snackData.type ? snackData.type : 'success'
        pageState.snackbarTimeout = snackData.timeout ? snackData.timeout : -1
    },
    showSnackbar(pageState, show) {
        pageState.snackbar = show
    },
    setCriticalCron(pageState, critical) {
        pageState.criticalCron = critical
    },
    sthowConfirmDialog(pageState, show) {
        pageState.confirmDialog = show
    },
    setConfirmDialogData(pageState, data) {
        pageState.confirmDialog = data !== null
        pageState.confirmData = data
    },
}


export const page = {
    namespaced: true,
    state,
    actions,
    mutations,
}
