import { userService } from '../_services'
import router from '../router'


const user = JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_KEY))


const state = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null }


const actions = {
    login({ dispatch, commit }, { username, password, next }) {
        commit('loginRequest', { username })

        return userService.login(username, password)
            .then(
                respUser => {
                    commit('loginSuccess', respUser)
                    router.push(next ? next : '/')
                    return true
                },
                error => {
                    commit('loginFailure', error)
                    dispatch('alert/error', error, { root: true })
                    return false
                },
            )
    },
    logout({ commit }) {
        userService.logout()
        commit('logout')
    },
    setLocale({ commit }, { lang, timezone }) {
        commit('setLocale', { lang, timezone })
    },
    setAvatar({ commit }, avatarId) {
        commit('setAvatar', avatarId)
    },
}


const mutations = {
    loginRequest(loginState, loginUser) {
        loginState.status = { loggingIn: true }
        loginState.user = loginUser
    },
    loginSuccess(loginState, loginUser) {
        loginState.status = { loggedIn: true }
        loginState.user = loginUser
    },
    loginFailure(loginState) {
        loginState.status = {}
        loginState.user = null
    },
    logout(loginState) {
        loginState.status = {}
        loginState.user = null
    },
    setLocale(loginState, locale) {
        loginState.user.user.lang = locale.lang
        loginState.user.user.timezone = locale.timezone
        const localUser = JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_KEY))
        localUser.user.lang = locale.lang
        localUser.user.timezone = locale.timezone
        localStorage.setItem(process.env.VUE_APP_STORAGE_KEY, JSON.stringify(localUser))
    },
    setAvatar(loginState, avatar) {
        loginState.user.user.avatar = avatar
        const localUser = JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_KEY))
        localUser.user.avatar = avatar
        localStorage.setItem(process.env.VUE_APP_STORAGE_KEY, JSON.stringify(localUser))
    },
}


export const account = {
    namespaced: true,
    state,
    actions,
    mutations,
}
