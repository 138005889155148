import { store } from '../_store'
import { getToken } from './auth'
import router from '../router'

const axios = require('axios')
const querystring = require('querystring')
const URL = require('url')


// const REST_DOMAIN = 'https://rest.mktimelapse.com:9000'
const REST_DOMAIN = process.env.VUE_APP_REST_DOMAIN


function getHeader() {
    return {
        headers: { Authorization: `Bearer ${getToken()}` },
        responseEncoding: 'utf8',
        responseType: 'json',
    }
}

function request(url, method, body, native, scope = {}) {
    store.dispatch('alert/clear')

    const headers = getHeader()
    if (scope.isForm === true) {
        headers['Content-Type'] = 'multipart/form-data'
    }

    const options = Object.assign(
        headers,
        {
            url,
            method,
            data: (body === true || !body) ? {} : body,
            timeout: scope.timeout || 10000,
        },
    )

    if (scope.download === true) {
        options.responseType = 'blob'
    }

    const urlParts = URL.parse(url)

    return axios(options)
        .then(response => {
            if (scope.download === true) {
                return response
            }

            const result = response.data
            if (result.error) {
                // make return message with original error message and human readable message
                const msg = `${method.toUpperCase()} ${urlParts.pathname}: ${result.error.message ? result.error.message : result.error}`
                // store.dispatch('alert/warning', msg, { root: true })
                // store.dispatch('input/showDialog', false)
                // return result
                throw msg
            } else if (native === true) {
                return result
            } else {
                return result.data
            }
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                store.dispatch(
                    'alert/error',
                    `${method.toUpperCase()} ${urlParts.pathname}: ${error.response && error.response.data ? error.response.data.error : error}`,
                    { root: true },
                )
                store.dispatch('account/logout')

                let redirectUrl = '/login'
                if (urlParts.pathname !== '/') {
                    redirectUrl += `?next=${urlParts.pathname}`
                }
                router.push({ path: redirectUrl })
            } else {
                let msg
                if (error.message) {
                    msg = `${method.toUpperCase()} ${urlParts.pathname}: ${error.message}`
                } else if (error.response) {
                    msg = `${method.toUpperCase()} ${urlParts.pathname}: ${error.response && error.response.data ? error.response.data.error : error}`
                } else {
                    msg = error
                }

                store.dispatch('alert/error', msg, { root: true })
                // store.dispatch('input/showDialog', false)

                throw new Error(msg)
            }
        })
}

function upload(file, altName) {
    const formData = new FormData()

    if (!altName) {
        altName = file.name
    }

    formData.append('image', file, altName)

    return request(
        `${REST_DOMAIN}/image_upload`,
        'post',
        formData,
        false,
        true,
    )
}

function setItem(type, payload) {
    const data = { payload }
    let method = 'post'
    if (payload.id) {
        data.query = { id: payload.id }
        method = 'put'
    }
    return request(
        `${REST_DOMAIN}/db/${type}`,
        method,
        data,
        true,
    )
}

function setPassword(payload) {
    return request(`${REST_DOMAIN}/password`, 'post', payload)
}

function changeUserSettings(payload) {
    return request(`${REST_DOMAIN}/user_settings`, 'post', payload)
}


function getRows(table, payload) {
    return request(`${REST_DOMAIN}/${table}`, 'post', payload)
}

function getRow(table, id, payload) {
    return request(`${REST_DOMAIN}/${table}/${id}`, 'post', payload)
}

function getDefault(table) {
    return request(`${REST_DOMAIN}/db/default/${table}`, 'get')
}

function getSystems(payload) {
    return request(`${REST_DOMAIN}/system`, 'post', payload)
}

function getOrderedSystems() {
    return request(`${REST_DOMAIN}/orderedSystems`, 'get')
}

function getSeries(type, id, payload) {
    return request(`${REST_DOMAIN}/series/${type}/${id}`, 'post', payload)
}

function getLevel(type, id, period) {
    if (!period) {
        period = 30
    }
    return request(`${REST_DOMAIN}/level/${type}/${id}?period=${period}`, 'get')
}

function getActiveProducts(date, csv = false) {
    let endpoint = `${REST_DOMAIN}/product/active`
    if (date) {
        endpoint += `?date=${date.format('YYYY-MM-DD')}`
    }
    if (csv) {
        endpoint += `${date ? '&' : '?'}export=1`
    }
    return request(endpoint, 'get', null, false, { download: !!csv })
}
function getPossibleProducts(date, locations) {
    const endpoint = `${REST_DOMAIN}/product/possible`
    let query = ''
    if (date) {
        query += `?date=${date.format('YYYY-MM-DD')}`
    }
    if (locations) {
        query += `${query ? '&' : '?'}locations=${locations.join(',')}`
    }
    return request(`${endpoint}${query}`, 'get')
}

function setInvStatus(projectId, systemId, items) {
    return request(`${REST_DOMAIN}/invStatus/${projectId}/${systemId}`, 'post', items)
}


function getInputData(type, id, queries) {
    if (!id) {
        id = ''
    }
    return request(`${REST_DOMAIN}/${type}/input/${id}${queries ? `?${querystring.stringify(queries)}` : ''}`, 'get')
}


function getSystemInfo(systemId) {
    return request(`${REST_DOMAIN}/systems/${systemId}/info`, 'get')
}


function getChecks(id, offset) {
    return request(`${REST_DOMAIN}/cameraCheck${(id ? `/${id}` : '')}${offset ? `?offset=${offset}` : ''}`, 'get')
}


function getCheckHistory(id, count) {
    return request(`${REST_DOMAIN}/cameraCheck/${id}/history${count ? `?count=${count}` : ''}`, 'get')
}


function setTask(payload) {
    return request(`${REST_DOMAIN}/tasks`, 'post', payload)
}

function doWipe(id) {
    return request(`${REST_DOMAIN}/wipe/${id}`, 'get', null, true)
}

function doWaterRefill(id) {
    return request(`${REST_DOMAIN}/refill/${id}`, 'get', null, true)
}

function newSession(id) {
    return request(`${REST_DOMAIN}/newSession/${id}`, 'get', null, true)
}

function pause(id, pauseState) {
    return request(`${REST_DOMAIN}/pause/${id}?v=${pauseState}`, 'get', null, true)
}

function capture(id) {
    return request(`${REST_DOMAIN}/capture/${id}`, 'get', null, true)
}

function setRef(id) {
    return request(`${REST_DOMAIN}/ref/${id}`, 'post', null, true)
}

function getRouterData(id) {
    return request(`${REST_DOMAIN}/router/${id}`, 'get')
}

function getArchive(id, payload) {
    return request(`${REST_DOMAIN}/archive/${id}`, 'post', payload, true)
}

function getLogs(id, number) {
    return request(`${REST_DOMAIN}/logs/${id}${number ? `/${number}` : ''}`, 'get', null, false, { timeout: 30000 })
}

function serialPortList(id) {
    return request(`${REST_DOMAIN}/com/${id}`, 'get')
}

function updateConfig(id) {
    return request(`${REST_DOMAIN}/update/${id}`, 'get')
}

function deploy(id) {
    return request(`${REST_DOMAIN}/deploy/${id}`, 'get')
}


export const rest = {
    getRows,
    getRow,
    getDefault,
    getSystems,
    getActiveProducts,
    getPossibleProducts,
    getSeries,
    getLevel,
    getInputData,
    setItem,
    setPassword,
    getChecks,
    getCheckHistory,
    getSystemInfo,
    setTask,
    request,
    upload,
    doWipe,
    pause,
    capture,
    setRef,
    getRouterData,
    setInvStatus,
    getArchive,
    getLogs,
    newSession,
    serialPortList,
    updateConfig,
    deploy,
    doWaterRefill,
    changeUserSettings,
    getOrderedSystems,
}
