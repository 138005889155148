import Vue from 'vue'
import Vuex from 'vuex'

import { page } from './page'
import { alert } from './alert'
import { account } from './account'
import { users } from './users'
import { input } from './input'
import { table } from './table'


Vue.use(Vuex)


export const store = new Vuex.Store({
    modules: {
        page,
        alert,
        account,
        users,
        input,
        table,
    },
})
