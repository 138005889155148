const defaultData = {
    id: -1,
    visible: false,
    type: null,
    title: '',
    data: {},
    confirm: true,
    fixed: {},
    filter: {},
    modified: (idx, item) => {
        console.log('modified', idx, item)
    },
    preValidate: () => Promise.resolve(true),
}

const state = {
    data: ({ ...defaultData }),
    new: ({ ...defaultData }),
}

const actions = {
    showDialog({ commit }, show) {
        commit('showDialog', show)
    },
    setInputDialog({ commit }, inputData) {
        commit('setInputDialog', inputData)
    },
    setNew({ commit }, newInput) {
        commit('setNew', newInput)
    },
    resetNew({ commit }) {
        commit('resetNew')
    },
    setFilter({ commit }, { name, value }) {
        commit('setFilter', { name, value })
    },
}


const mutations = {
    showDialog(istate, show) {
        istate.data.visible = show
    },
    setInputDialog(istate, inputData) {
        if (inputData) {
            Object.assign(istate.data, inputData)
        } else {
            istate.data = ({ ...istate.new })
        }
    },
    setNew(istate, newInput) {
        if (!newInput) {
            newInput = {}
        }
        istate.new = ({ ...defaultData, ...newInput })
    },
    resetNew(istate) {
        istate.new = ({ ...istate.new, ...{ id: istate.new.id - 1 } })
    },
    setFilter(istate, { name, value }) {
        const newFilter = {}
        newFilter[name] = value

        istate.data.filter = { ...istate.data.filter, ...newFilter }
        istate.data = { ...istate.data }
    },
}


export const input = {
    namespaced: true,
    state,
    actions,
    mutations,
}
