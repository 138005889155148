import { getToken } from '../_helpers/auth'
// import { rest } from '../_helpers/rest'
const axios = require('axios')


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.VUE_APP_STORAGE_KEY)
}

function handleResponse(response) {
    const { data } = response
    if (response.status >= 400) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout()
            // location.reload(true);
        }

        const error = (data && (data.message || data.error)) || response.statusText
        return Promise.reject(error)
    }

    return data
}

function login(username, password) {
    const body = { username, password }

    return axios.post(
        `${process.env.VUE_APP_REST_DOMAIN}/auth`,
        body,
        {
            headers: { Authorization: `Bearer ${getToken()}` },
            responseEncoding: 'utf8',
            responseType: 'json',
        },
    )
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(process.env.VUE_APP_STORAGE_KEY, JSON.stringify(user))
            }
            return user
        })
}


export const userService = {
    login,
    logout,
}
