import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from './views/LoginPage.vue'
// eslint-disable-next-line import/no-cycle
import { store } from './_store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            meta: { title: 'Login' },
            component: LoginPage,
        },
        {
            path: '/',
            name: 'systems',
            component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
            menu: true,
            icon: 'mdi-view-dashboard',
            meta: {
                title: 'Dashboard',
                search: true,
            },
        },
        {
            path: '/projects',
            name: 'projects',
            component: () => import(/* webpackChunkName: "projects" */ './views/ProjectList.vue'),
            menu: true,
            icon: 'mdi-folder',
            meta: {
                title: 'Projects',
                search: false,
                role: 2,
            },
        },
        {
            path: '/inventory',
            name: 'inventory',
            component: () => import(/* webpackChunkName: "inventory" */ './views/Inventory.vue'),
            menu: true,
            icon: 'mdi-format-list-bulleted',
            meta: {
                title: 'Inventory',
                role: 2,
                items: [
                    {
                        name: 'Camera',
                        value: 'camera',
                        icon: 'mdi-camera-outline',
                    },
                    {
                        name: 'Computer',
                        value: 'computer',
                        icon: 'mdi-laptop',
                    },
                    {
                        name: 'Housing',
                        value: 'housing',
                        icon: 'mdi-archive-outline',
                    },
                    {
                        name: 'Router',
                        value: 'router',
                        icon: 'mdi-router-wireless',
                    },
                    {
                        name: '',
                        menu: [
                            {
                                name: 'battery',
                                icon: 'mdi-battery-charging',
                            },
                            {
                                name: 'control',
                                icon: 'mdi-gamepad-square-outline',
                            },
                            {
                                name: 'diskstation',
                                icon: 'mdi-desktop-tower',
                            },
                            {
                                name: 'dryer',
                                icon: 'mdi-hair-dryer',
                            },
                            {
                                name: 'lens',
                                icon: 'mdi-camera-iris',
                            },
                            {
                                name: 'mast',
                                icon: 'mdi-eiffel-tower',
                            },
                            {
                                name: 'nas',
                                icon: 'mdi-nas',
                            },
                            {
                                name: 'solar',
                                icon: 'mdi-solar-power',
                            },
                            {
                                name: 'switch',
                                icon: 'mdi-toggle-switch',
                            },
                            {
                                name: 'webcam',
                                icon: 'mdi-webcam',
                            },
                        ],
                        icon: 'mdi-dots-horizontal',
                    },
                ],
            },
        },
        {
            path: '/inventory/:id(\\d+)',
            name: 'inventoryId',
            component: () => import(/* webpackChunkName: "inventory" */ './views/Inventory.vue'),
            meta: {
                title: 'Inventory',
                role: 2,
            },
        },
        {
            path: '/inventory/:category([a-z]+)',
            name: 'inventoryCat',
            component: () => import(/* webpackChunkName: "inventory" */ './views/Inventory.vue'),
            meta: {
                title: 'Inventory',
                role: 2,
            },
        },
        {
            path: '/computer',
            name: 'computer',
            component: () => import(/* webpackChunkName: "computer" */ './views/Computer.vue'),
            menu: true,
            icon: 'mdi-laptop',
            meta: {
                title: 'Computer',
                sort: false,
                role: 2,
            },
        },
        {
            path: '/computer/:id(\\d+)',
            name: 'computerId',
            component: () => import(/* webpackChunkName: "computer" */ './views/Computer.vue'),
            meta: {
                title: 'Computer',
                role: 2,
            },
        },

        {
            path: '/sims',
            name: 'sims',
            component: () => import(/* webpackChunkName: "sims" */ './views/Sims.vue'),
            menu: true,
            icon: 'mdi-sim',
            meta: {
                title: 'SIM',
                sort: false,
                role: 2,
            },
        },
        {
            path: '/sims/:id(\\d+)',
            name: 'simId',
            component: () => import(/* webpackChunkName: "sims" */ './views/Sims.vue'),
            meta: {
                title: 'SIM',
                role: 2,
            },
        },

        {
            path: '/contacts',
            name: 'contacts',
            component: () => import(/* webpackChunkName: "contacts" */ './views/Contacts.vue'),
            menu: true,
            icon: 'mdi-contacts',
            meta: {
                title: 'Contacts',
                sort: false,
                role: 2,
                search: true,
            },
        },
        {
            path: '/contacts/:id(\\d+)',
            name: 'contactId',
            component: () => import(/* webpackChunkName: "computer" */ './views/Contacts.vue'),
            meta: {
                title: 'Contacts',
                role: 2,
            },
        },
        {
            path: '/contacts/project/:id(\\d+)',
            name: 'contactProjectId',
            component: () => import(/* webpackChunkName: "computer" */ './views/Contacts.vue'),
            meta: {
                title: 'Contacts',
                role: 2,
            },
        },
        {
            path: '/map',
            name: 'map',
            component: () => import(/* webpackChunkName: "map" */ './views/Map.vue'),
            menu: true,
            icon: 'mdi-map',
            meta: {
                title: 'Map',
                sort: false,
                role: 2,
            },
        },
        {
            path: '/check',
            name: 'check',
            component: () => import(/* webpackChunkName: "check" */ './views/CameraCheck.vue'),
            menu: true,
            icon: 'mdi-clipboard-check-outline',
            meta: {
                title: 'Camera Check',
                sort: false,
                role: 2,
            },
        },
        {
            path: '/users',
            name: 'users',
            component: () => import(/* webpackChunkName: "users" */ './views/Users.vue'),
            menu: true,
            icon: 'mdi-account-box',
            meta: {
                title: 'Users',
                sort: false,
                role: 0,
            },
        },
        {
            path: '/check/:id',
            name: 'checkId',
            component: () => import(/* webpackChunkName: "check" */ './views/CameraCheck.vue'),
            menu: false,
            meta: {
                title: 'Camera Check',
                search: false,
                sort: false,
                role: 2,
            },
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import(/* webpackChunkName: "settings" */ './views/Settings.vue'),
            menu: false,
            icon: 'mdi-settings',
            meta: {
                title: 'Settings',
                search: false,
                sort: false,
            },
        },
        {
            path: '/system/:id/settings',
            name: 'system_settings',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Settings',
                selectedTab: 'settings',
                search: false,
                sort: false,
                role: 2,
            },
        },
        {
            path: '/system/:id/charts',
            name: 'system_charts',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Charts',
                selectedTab: 'charts',
                search: false,
                sort: false,
            },
        },
        {
            path: '/system/:id/config',
            name: 'system_config',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Configuration',
                selectedTab: 'config',
                search: false,
                sort: false,
                role: 2,
            },
        },
        {
            path: '/system/:id/notes',
            name: 'system_notes',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Notes',
                selectedTab: 'notes',
                search: false,
                sort: false,
                role: 2,
            },
        },
        {
            path: '/system/:id/contacts',
            name: 'system_contacts',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Contacts',
                selectedTab: 'contacts',
                search: false,
                sort: false,
                role: 2,
            },
        },
        {
            path: '/system/:id/image',
            name: 'system_image',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Image',
                selectedTab: 'image',
                search: false,
                sort: false,
            },
        },
        {
            path: '/system/:id/check',
            name: 'system_check',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Camera Check',
                selectedTab: 'check',
                search: false,
                sort: false,
                role: 2,
            },
        },
        {
            path: '/system/:id/report',
            name: 'system_report',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Camera Report',
                selectedTab: 'report',
                search: false,
                sort: false,
                role: 2,
            },
        },
        {
            path: '/system/:id/notifications',
            name: 'system_notifications',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Notifications',
                selectedTab: 'notifications',
                search: false,
                sort: false,
                role: 0,
            },
        },
        {
            path: '/system/:id/actions',
            name: 'system_actions',
            component: () => import(/* webpackChunkName: "actions" */ './views/System.vue'),
            meta: {
                title: 'System Actions',
                role: 0,
                selectedTab: 'actions',
                search: false,
                sort: false,
            },
            icon: 'mdi-ticket',
        },
        {
            path: '/system/:id/logs',
            name: 'system_logs',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Log Book',
                selectedTab: 'logs',
                search: false,
                sort: false,
                role: 0,
            },
        },
        {
            path: '/system/:id/weather',
            name: 'system_weather',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Weather',
                selectedTab: 'gauge',
                selectedSubTab: 'weather',
                search: false,
                sort: false,
            },
        },
        {
            path: '/system/:id',
            name: 'system',
            component: () => import(/* webpackChunkName: "system" */ './views/System.vue'),
            meta: {
                title: 'System Image',
                selectedTab: 'image',
                search: false,
                sort: false,
            },
        },
        {
            path: '/project/:id',
            name: 'project',
            component: () => import(/* webpackChunkName: "project" */ './views/Project.vue'),
            meta: {
                title: 'Project',
                search: false,
                sort: false,
                role: 2,
            },
        },
        {
            path: '/project/:id/report',
            name: 'project',
            component: () => import(/* webpackChunkName: "project" */ './views/Project.vue'),
            meta: {
                title: 'Project Report',
                search: false,
                sort: false,
                role: 2,
            },
        },
        {
            path: '/status',
            name: 'status',
            component: () => import(/* webpackChunkName: "status" */ './views/Status.vue'),
            meta: {
                title: 'Status',
                role: 1,
            },
            menu: true,
            icon: 'mdi-camera',
        },
        {
            path: '/deploy',
            name: 'deploy',
            component: () => import(/* webpackChunkName: "deploy" */ './views/Deploy.vue'),
            meta: {
                title: 'Deploy',
                role: 0,
            },
            menu: true,
            icon: 'mdi-wrench',
        },
        /* {
            path: '/about',
            name: 'about',
            component: () => import('./views/About.vue'),
            meta: { title: 'About' },
            menu: true,
            icon: 'mdi-question-answer',
        }, */
        { path: '*', redirect: '/' },
        {
            path: '/404',
            name: '404',
            component: () => import('./views/404.vue'),
        },
    ],
})

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login']
    const authRequired = !publicPages.includes(to.path)
    // const loggedIn = localStorage.getItem(process.env.VUEW_APP_STORAGE_KEY);
    const { loggedIn } = store.state.account.status

    /* const { user } = JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_KEY))
    if (to.meta.role && to.meta.role < user.role) {
        return next(from.path)
    } */

    if (authRequired && !loggedIn) {
        let redirectUrl = '/login'
        if (to.path !== '/') {
            redirectUrl += `?next=${to.path}`
        }
        return next(redirectUrl)
    }

    document.title = `MK - ${to.meta.title}`

    next()
})

export default router
